export function formatCountShort(value: number): string {
  const { number, postfix } = formatCountShortSeparate(value);
  return `${number}${postfix}`;
}

export function formatCountShortSeparate(value: number): {
  number: number | string;
  postfix: string;
} {
  if (value >= 1000000000) {
    return {
      number: floorWithOneDecimal(value / 1000000000),
      postfix: "B",
    };
  }
  if (value >= 1000000) {
    return {
      number: floorWithOneDecimal(value / 1000000),
      postfix: "M",
    };
  }
  if (value >= 1000) {
    return {
      number: floorWithOneDecimal(value / 1000),
      postfix: "K",
    };
  }
  return { number: value.toFixed(), postfix: "" };
}

// We are avoiding rounding up with the .toFixed(1), e.g. 999.99M is not quite
// 1B, so we floor past 1st decimal until we actually pass 1B.
function floorWithOneDecimal(value: number) {
  // 1.49 -> 1.4
  // 1.50 -> 1.5
  // 1.51 -> 1.5
  return (Math.floor(value * 10) / 10).toFixed(1).replace(".0", "");
}
