import { useParams } from "react-router-dom";
import * as df from "date-fns";
import { TrackStreamsGraph } from "./TrackStreamsGraph";
import { useEffect } from "react";
import amplitude from "amplitude-js";
import { ActivityIndicator } from "./ActivityIndicator";

interface Track {
  isrc: string;
  name: string;
  product: {
    releaseDate: string;
  };
}
interface TrackResponse {
  track: Track;
}

interface TrackStreamsResponse {
  streams: { data: number | null; date: string }[];
}

export const TrackStreams: React.FC<{ track: Track; artistId: string }> = ({
  track,
  artistId,
}) => {
  const todayDate = df.formatISO(new Date(), { representation: "date" });

  const startDate = track.product.releaseDate;
  const endDate = todayDate;

  const streamsData = { streams: [] }; //TODO: re-implement using GraphQL
  //const trackStreamsPath = `/api/artists/${artistId}/track/${track.isrc}/streams?startDate=${startDate}&endDate=${endDate}`;
  //const { data: streamsData } = useGet<TrackStreamsResponse>(trackStreamsPath);

  const interval = {
    start: df.parseISO(startDate),
    end: df.parseISO(endDate),
  };

  return streamsData != null ? (
    <TrackStreamsGraph
      interval={interval}
      data={[streamsData.streams]}
      width={800}
      height={300}
    />
  ) : (
    <ActivityIndicator />
  );
};

export const Track: React.FC<{}> = ({}) => {
  const { isrc, artistId } = useParams<{ isrc: string; artistId: string }>();
  //const path = `/api/artists/${artistId}/track/${isrc}`;
  //const { data } = useGet<TrackResponse>(path);
  const data: any = null; // TODO: re-implement using GraphQL

  useEffect(() => {
    amplitude.getInstance().logEvent("Track Page loaded", { artistId, isrc });
  }, []);

  return data != null ? (
    <div className="box">
      <div>{data.track.name}</div>
      <div>{data.track.isrc}</div>
      <TrackStreams track={data.track} artistId={artistId} />
    </div>
  ) : (
    <ActivityIndicator />
  );
};
