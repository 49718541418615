const auth0 =
  process.env.REACT_APP_AUTH0_DOMAIN != null &&
  process.env.REACT_APP_AUTH0_CLIENT_ID != null &&
  process.env.REACT_APP_AUTH0_AUDIENCE != null
    ? {
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        redirectUri: window.location.origin,
      }
    : null;

const amplitude =
  process.env.REACT_APP_AMPLITUDE_API_KEY != null
    ? {
        apiKey: process.env.REACT_APP_AMPLITUDE_API_KEY,
      }
    : null;

export const config = {
  auth0,
  amplitude,
  rtiApiUrl: process.env.REACT_APP_RTI_API_URL,
};
