import "./App.css";

import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Login } from "./components/Login";
import { LabelTracks } from "./components/LabelTracks";
import { Header } from "./components/Header";
import { Track } from "./components/Track";
import { ActivityIndicator } from "./components/ActivityIndicator";
import { NotFound } from "./pages/NotFound";

import { useInitAmplitude } from "./hooks/useInitAmplitude";
import { config } from "./config";
import { GraphQLClientProvider } from "./graphql/urql";

function App() {
  return config.auth0 ? (
    <Auth0Provider {...config.auth0}>
      <Router>
        <InnerApp />
      </Router>
    </Auth0Provider>
  ) : (
    <h1>missing Auth0 config</h1>
  );
}

function InnerApp() {
  const { isAuthenticated, isLoading, user } = useAuth0();

  useInitAmplitude(user);

  if (isLoading) {
    return <ActivityIndicator />;
  }

  return isAuthenticated ? (
    <GraphQLClientProvider>
      <Switch>
        <Route exact path="/artists/:artistId/tracks/:isrc">
          <WithHeader>
            <Track />
          </WithHeader>
        </Route>
        <Route exact path="/">
          <WithHeader>
            <LabelTracks />
          </WithHeader>
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </GraphQLClientProvider>
  ) : (
    <Switch>
      <Route exact path="/">
        <Login />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

const WithHeader: React.FC<{}> = ({ children }) => (
  <div className="App">
    <Header />
    {children}
  </div>
);

export default App;
