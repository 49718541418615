import React from "react";

import "./NotFound.css";

export const NotFound: React.FC<{}> = () => {
  return (
    <div className="wrapper">
      <div className="top">
        <img alt="logo" className="logo" src="/img/insights-logo.svg" />
        <h1 className="title">Oops!</h1>
        <div className="text">
          <div>
            The page you are looking for can't be opened. Download the RTI
            mobile app to access this information.
          </div>
        </div>
      </div>
      <div className="store-links">
        <a
          className="store-link"
          href="https://apps.apple.com/us/app/real-time-insights-sme/id1511208600"
        >
          <img alt="app store logo" src="/img/apple-store.svg" />
        </a>
        <a
          className="store-link"
          href="https://play.google.com/store/apps/details?id=com.sonymusic.sma"
        >
          <img alt="google play logo" src="/img/google-play.svg" />
        </a>
      </div>
    </div>
  );
};
