import { Logout } from "./Login";
import { Route, Switch } from "react-router";
export const Header: React.FC<{}> = () => {
  return (
    <header className="App-header">
      <nav className="main-nav">
        <div className="left">
          <h1>RTI</h1>
          <nav className="main">
            <div className="active">Dashboard</div>
            <div>Library</div>
            <div>Audience</div>
          </nav>
        </div>
        <div className="right">
          <a
            className="header-button"
            href="mailto:?cc=sonymusicartists@sonymusic.com,atri.raychowdhury@sonymusic.com&subject=Invitation%20%7C%20Stream%20Spikes%20Tool&body=Hi%2C%20I%20am%20inviting%20you%20to%20a%20new%20project%20the%20Data%20Strategy%20team%20is%20working%20on%20that%20surfaces%20streaming%20spikes%20across%20all%20your%20artists%20%E2%80%94%20this%20way%20you%E2%80%99ll%20never%20miss%20a%20beat."
          >
            <button>Invite Team-Member</button>
          </a>
          <Logout />
        </div>
      </nav>

      <Switch>
        <Route path="/">
          <nav className="weeks">
            <a href="#3-weeks">Three Weeks Growth</a>
            <a href="#2-weeks">Two Weeks Growth</a>
            <a href="#1-week">One Weeks Growth</a>
          </nav>
        </Route>
      </Switch>
    </header>
  );
};
