import * as df from "date-fns";
const dateFormat = "yyyy-MM-dd";

export function parseDateString(s: string) {
  return df.parse(s, dateFormat, new Date());
}

export function sortByDate(a: { date: string }, b: { date: string }) {
  return parseDateString(b.date).getTime() - parseDateString(a.date).getTime();
}
