import { useAuth0 } from "@auth0/auth0-react";
import logo from "../logo.svg";

export const Login: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <div id="login-box">
      <div>
        <img className="logo" src={logo} height={60} alt="logo" />
        <h1>RTI</h1>
        <button onClick={() => loginWithRedirect()}>Log in</button>
      </div>
    </div>
  );
};

export const Logout: React.FC = () => {
  const { logout } = useAuth0();

  return (
    <div className="header-button">
      <button onClick={() => logout()}>Log Out</button>
    </div>
  );
};
