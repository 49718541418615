import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date scalar type */
  Date: string;
};

export type AgeRange = {
  __typename?: 'AgeRange';
  end: Maybe<Scalars['Int']>;
  start: Scalars['Int'];
};

export type Artist = {
  __typename?: 'Artist';
  demographics: Maybe<Array<DspDemographics>>;
  entities: EntityConnection;
  firstName: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Maybe<Scalars['String']>;
  track: Maybe<Track>;
  trackMetrics: Array<Array<TrackMetrics>>;
  tracks: Array<Track>;
  video: Maybe<Video>;
  videos: Array<Video>;
};


export type ArtistDemographicsArgs = {
  queries: Array<MetricQuery>;
};


export type ArtistEntitiesArgs = {
  first: InputMaybe<Scalars['Int']>;
  isFavorite: InputMaybe<Scalars['Boolean']>;
  orderBy: InputMaybe<EntityOrder>;
  search: InputMaybe<Scalars['String']>;
};


export type ArtistTrackArgs = {
  isrc: Scalars['ID'];
};


export type ArtistTrackMetricsArgs = {
  queries: Array<TrackMetricQuery>;
};


export type ArtistTracksArgs = {
  orderBy: InputMaybe<TracksOrder>;
};


export type ArtistVideoArgs = {
  id: Scalars['ID'];
};

export type Connection = {
  pageInfo: PageInfo;
};

export type Dated = {
  date: Scalars['Date'];
};

export type DemographicsAgeBand = {
  __typename?: 'DemographicsAgeBand';
  ageRange: Maybe<AgeRange>;
  gender: Gender;
  percentage: Maybe<Scalars['Float']>;
};

export type Dsp = {
  __typename?: 'Dsp';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DspDemographics = {
  __typename?: 'DspDemographics';
  ageBands: Array<DemographicsAgeBand>;
  dsp: Dsp;
};

export enum DspId {
  Amazon = 'amazon',
  Apple = 'apple',
  Spotify = 'spotify',
  Tiktok = 'tiktok',
  Youtube = 'youtube'
}

export type Edge = {
  cursor: Scalars['String'];
};

export type Entity = Product | Track | Video;

export type EntityConnection = {
  __typename?: 'EntityConnection';
  edges: Array<EntityEdge>;
  totalCount: Scalars['Int'];
};

export type EntityEdge = {
  __typename?: 'EntityEdge';
  node: Entity;
};

export type EntityOrder = {
  direction: OrderDirection;
  field: EntityOrderField;
};

export enum EntityOrderField {
  EntityName = 'ENTITY_NAME',
  FavoriteTime = 'FAVORITE_TIME'
}

export enum Gender {
  Female = 'female',
  Male = 'male',
  Neutral = 'neutral',
  Unknown = 'unknown'
}

export type Image = {
  __typename?: 'Image';
  height: Maybe<Scalars['Int']>;
  key: Maybe<Scalars['String']>;
  uri: Scalars['String'];
  width: Maybe<Scalars['Int']>;
};

export type InputInterval = {
  end: Scalars['Date'];
  start: Scalars['Date'];
};

export type Interval = {
  __typename?: 'Interval';
  end: Scalars['Date'];
  start: Scalars['Date'];
};

export type Label = {
  __typename?: 'Label';
  id: Scalars['String'];
  name: Scalars['String'];
  trackAnalysis: Array<TrackAnalysis>;
};


export type LabelTrackAnalysisArgs = {
  options: InputMaybe<TrackAnalysisOptions>;
};

export type LabelConnection = {
  __typename?: 'LabelConnection';
  edges: Array<LabelEdge>;
  totalCount: Scalars['Int'];
};

export type LabelEdge = {
  __typename?: 'LabelEdge';
  node: Label;
};

export type MetricQuery = {
  interval: InputInterval;
  market: InputMaybe<Scalars['String']>;
};

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['String'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Scalars['String'];
};

export type Playlist = {
  __typename?: 'Playlist';
  dsp: Maybe<Dsp>;
  dspPlaylistId: Scalars['ID'];
  id: Scalars['ID'];
  image: Maybe<Image>;
  market: Maybe<Scalars['String']>;
  name: Scalars['String'];
  numTracks: Maybe<Scalars['Int']>;
  rank: Maybe<Scalars['Int']>;
  type: Maybe<PlaylistType>;
  uri: Maybe<Scalars['String']>;
};

export enum PlaylistType {
  Algorithmic = 'ALGORITHMIC',
  Amazon = 'AMAZON',
  Editorial = 'EDITORIAL',
  Other = 'OTHER',
  SonyOwned = 'SONY_OWNED'
}

export type Product = {
  __typename?: 'Product';
  digitalTitleSuppl: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image: Image;
  isExplicit: Scalars['Boolean'];
  name: Scalars['String'];
  releaseDate: Maybe<Scalars['Date']>;
};

export type Query = {
  __typename?: 'Query';
  artist: Maybe<Artist>;
  label: Maybe<Label>;
  labels: LabelConnection;
  regions: Array<Region>;
};


export type QueryArtistArgs = {
  id: Scalars['ID'];
};


export type QueryLabelArgs = {
  id: Scalars['ID'];
};

export type Region = {
  __typename?: 'Region';
  countryCode: Scalars['String'];
  countryName: Scalars['String'];
  id: Scalars['ID'];
  translations: Array<RegionTranslation>;
};

export type RegionTranslation = {
  __typename?: 'RegionTranslation';
  countryName: Scalars['String'];
  locale: Scalars['String'];
};

export type Track = {
  __typename?: 'Track';
  artists: Array<Artist>;
  /** List of unique playlists the track is on or has been on on the past */
  currentPlaylists: TrackPlaylistCurrentConnection;
  demographics: Array<Array<DspDemographics>>;
  id: Scalars['ID'];
  isExplicit: Scalars['Boolean'];
  isFavorite: Scalars['Boolean'];
  isrc: Scalars['ID'];
  lyricsVersion: Maybe<Scalars['String']>;
  metrics: Array<Array<TrackMetrics>>;
  name: Scalars['String'];
  nameSuppl: Maybe<Scalars['String']>;
  playlist: Maybe<TrackPlaylist>;
  previousPlaylists: TrackPlaylistPreviousConnection;
  product: Product;
  /** An optional supplementary part of the Track name, typically used to distinguish between different versions of similar Tracks with the same name. */
  releaseDate: Maybe<Scalars['Date']>;
  tiktokMetrics: Array<Array<TrackTiktokMetrics>>;
};


export type TrackCurrentPlaylistsArgs = {
  dsp: DspId;
  first: InputMaybe<Scalars['Int']>;
  market: InputMaybe<Scalars['String']>;
  orderBy: InputMaybe<Array<TrackCurrentPlaylistsOrder>>;
  playlistTypes: InputMaybe<Array<PlaylistType>>;
};


export type TrackDemographicsArgs = {
  queries: Array<TrackMetricQuery>;
};


export type TrackMetricsArgs = {
  queries: Array<TrackMetricQuery>;
};


export type TrackPlaylistArgs = {
  id: Scalars['ID'];
};


export type TrackPreviousPlaylistsArgs = {
  dsp: DspId;
  first: InputMaybe<Scalars['Int']>;
  market: InputMaybe<Scalars['String']>;
  orderBy: InputMaybe<Array<TrackPreviousPlaylistsOrder>>;
  playlistTypes: InputMaybe<Array<PlaylistType>>;
};


export type TrackTiktokMetricsArgs = {
  queries: Array<TrackMetricQuery>;
};

export type TrackAnalysis = {
  __typename?: 'TrackAnalysis';
  categories: Array<TrackAnalysisCategory>;
  metrics: Array<TrackMetrics>;
  track: Track;
  weeks: Array<TrackChangeWeek>;
};

export enum TrackAnalysisCategory {
  BreakingOut = 'BREAKING_OUT',
  GainingMomentum = 'GAINING_MOMENTUM',
  MicroMoment = 'MICRO_MOMENT'
}

export enum TrackAnalysisMetric {
  StreamsLeanForward = 'STREAMS_LEAN_FORWARD',
  StreamsTotal = 'STREAMS_TOTAL'
}

export type TrackAnalysisOptions = {
  analysisMetric: InputMaybe<TrackAnalysisMetric>;
  dspId: InputMaybe<TrackDspId>;
  market: InputMaybe<Scalars['String']>;
  trackReleaseInterval: InputMaybe<InputInterval>;
};

export type TrackAnalysisStreams = {
  __typename?: 'TrackAnalysisStreams';
  date: Scalars['Date'];
  streams: Maybe<Scalars['Int']>;
};

export type TrackChangeWeek = {
  __typename?: 'TrackChangeWeek';
  average: Maybe<Scalars['Float']>;
  changePercentage: Maybe<Scalars['Float']>;
  interval: Interval;
};

export type TrackCurrentPlaylistsOrder = {
  direction: InputMaybe<OrderDirection>;
  field: TrackCurrentPlaylistsOrderField;
};

export enum TrackCurrentPlaylistsOrderField {
  CurrentPosition = 'CURRENT_POSITION',
  EarliestPositionDate = 'EARLIEST_POSITION_DATE',
  PlaylistName = 'PLAYLIST_NAME',
  PositionChange = 'POSITION_CHANGE',
  StreamsLast_7Days = 'STREAMS_LAST_7_DAYS'
}

export enum TrackDspId {
  Amazon = 'amazon',
  Apple = 'apple',
  Spotify = 'spotify'
}

export type TrackMetricQuery = {
  dspId: InputMaybe<TrackDspId>;
  interval: InputInterval;
  market: InputMaybe<Scalars['String']>;
};

export type TrackMetrics = Dated & {
  __typename?: 'TrackMetrics';
  date: Scalars['Date'];
  streams: Maybe<Scalars['Int']>;
};

export type TrackPlaylist = TrackPlaylistInterface & {
  __typename?: 'TrackPlaylist';
  metrics: Array<Array<TrackPlaylistMetrics>>;
  playlist: Playlist;
  track: Track;
};


export type TrackPlaylistMetricsArgs = {
  queries: Array<MetricQuery>;
};

export type TrackPlaylistCurrent = TrackPlaylistInterface & {
  __typename?: 'TrackPlaylistCurrent';
  currentPosition: Maybe<Scalars['Int']>;
  earliestPositionDate: Maybe<Scalars['Date']>;
  numWeeksOn: Maybe<Scalars['Int']>;
  playlist: Playlist;
  positionChange: Maybe<Scalars['Int']>;
  previousPosition: Maybe<Scalars['Int']>;
  streamsLast7Days: Maybe<Scalars['Int']>;
  track: Track;
};

export type TrackPlaylistCurrentConnection = {
  __typename?: 'TrackPlaylistCurrentConnection';
  edges: Array<TrackPlaylistCurrentEdge>;
  totalCount: Scalars['Int'];
};

export type TrackPlaylistCurrentEdge = {
  __typename?: 'TrackPlaylistCurrentEdge';
  node: TrackPlaylistCurrent;
};

export type TrackPlaylistInterface = {
  playlist: Playlist;
  track: Track;
};

export type TrackPlaylistMetrics = Dated & {
  __typename?: 'TrackPlaylistMetrics';
  currentPosition: Maybe<Scalars['Int']>;
  date: Scalars['Date'];
  streams: Maybe<Scalars['Int']>;
};

export type TrackPlaylistPrevious = TrackPlaylistInterface & {
  __typename?: 'TrackPlaylistPrevious';
  earliestPositionDate: Maybe<Scalars['Date']>;
  lastPositionDate: Maybe<Scalars['Date']>;
  numDaysOn: Maybe<Scalars['Int']>;
  playlist: Playlist;
  track: Track;
};

export type TrackPlaylistPreviousConnection = {
  __typename?: 'TrackPlaylistPreviousConnection';
  edges: Array<TrackPlaylistPreviousEdge>;
  totalCount: Scalars['Int'];
};

export type TrackPlaylistPreviousEdge = {
  __typename?: 'TrackPlaylistPreviousEdge';
  node: TrackPlaylistPrevious;
};

export type TrackPreviousPlaylistsOrder = {
  direction: InputMaybe<OrderDirection>;
  field: TrackPreviousPlaylistsOrderField;
};

export enum TrackPreviousPlaylistsOrderField {
  LastPositionDate = 'LAST_POSITION_DATE',
  PlaylistName = 'PLAYLIST_NAME'
}

export type TrackTiktokMetrics = Dated & {
  __typename?: 'TrackTiktokMetrics';
  comments: Maybe<Scalars['Int']>;
  creations: Maybe<Scalars['Int']>;
  date: Scalars['Date'];
  favorites: Maybe<Scalars['Int']>;
  likes: Maybe<Scalars['Int']>;
  shares: Maybe<Scalars['Int']>;
  video_views: Maybe<Scalars['Int']>;
};

export type TracksOrder = {
  direction: InputMaybe<OrderDirection>;
  field: TracksOrderField;
  query: InputMaybe<TrackMetricQuery>;
};

export enum TracksOrderField {
  TiktokCreations = 'TIKTOK_CREATIONS',
  TiktokCreationsChange = 'TIKTOK_CREATIONS_CHANGE'
}

export type TrafficSource = {
  __typename?: 'TrafficSource';
  lean: TrafficSourceLean;
  name: Scalars['String'];
  value: Maybe<Scalars['Int']>;
};

export enum TrafficSourceLean {
  LeanBack = 'LEAN_BACK',
  LeanForward = 'LEAN_FORWARD'
}

export type Video = {
  __typename?: 'Video';
  dateUploaded: Maybe<Scalars['Date']>;
  demographics: Array<Array<DspDemographics>>;
  dsp: VideoDsp;
  dspVideoId: Maybe<Scalars['ID']>;
  durationSeconds: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  metrics: Array<Array<VideoMetrics>>;
  thumbnails: YoutubeThumbnails;
  title: Scalars['String'];
};


export type VideoDemographicsArgs = {
  queries: Array<VideoMetricQuery>;
};


export type VideoMetricsArgs = {
  queries: Array<VideoMetricQuery>;
};

export enum VideoDsp {
  Youtube = 'youtube'
}

export type VideoImage = {
  __typename?: 'VideoImage';
  height: Scalars['Int'];
  url: Scalars['String'];
  width: Scalars['Int'];
};

export type VideoMetricQuery = {
  dspId: InputMaybe<VideoDsp>;
  interval: InputInterval;
  market: InputMaybe<Scalars['String']>;
};

export type VideoMetrics = Dated & {
  __typename?: 'VideoMetrics';
  averageViewDurationPercentage: Maybe<Scalars['Float']>;
  averageViewDurationSeconds: Maybe<Scalars['Float']>;
  comments: Maybe<Scalars['Int']>;
  date: Scalars['Date'];
  dislikes: Maybe<Scalars['Int']>;
  likes: Maybe<Scalars['Int']>;
  trafficSources: Maybe<Array<TrafficSource>>;
  views: Maybe<Scalars['Int']>;
};

export type YoutubeThumbnails = {
  __typename?: 'YoutubeThumbnails';
  default: Maybe<VideoImage>;
  high: Maybe<VideoImage>;
  maxres: Maybe<VideoImage>;
  medium: Maybe<VideoImage>;
  standard: Maybe<VideoImage>;
};

export type LabelAnalysisTrackFragment = { __typename?: 'Track', isrc: string, name: string, product: { __typename?: 'Product', id: string }, artists: Array<{ __typename?: 'Artist', id: string, fullName: string }> };

export type LabelAnalysisTrackFieldsFragment = { __typename?: 'TrackAnalysis', categories: Array<TrackAnalysisCategory>, track: { __typename?: 'Track', isrc: string, name: string, product: { __typename?: 'Product', id: string }, artists: Array<{ __typename?: 'Artist', id: string, fullName: string }> }, weeks: Array<{ __typename?: 'TrackChangeWeek', changePercentage: number | null, average: number | null, interval: { __typename?: 'Interval', start: string, end: string } }>, metrics: Array<{ __typename?: 'TrackMetrics', streams: number | null, date: string }> };

export type LabelTrackAnalysisQueryVariables = Exact<{
  labelId: Scalars['ID'];
  trackAnalysisOptions: InputMaybe<TrackAnalysisOptions>;
}>;


export type LabelTrackAnalysisQuery = { __typename?: 'Query', label: { __typename?: 'Label', id: string, trackAnalysis: Array<{ __typename?: 'TrackAnalysis', categories: Array<TrackAnalysisCategory>, track: { __typename?: 'Track', isrc: string, name: string, product: { __typename?: 'Product', id: string }, artists: Array<{ __typename?: 'Artist', id: string, fullName: string }> }, weeks: Array<{ __typename?: 'TrackChangeWeek', changePercentage: number | null, average: number | null, interval: { __typename?: 'Interval', start: string, end: string } }>, metrics: Array<{ __typename?: 'TrackMetrics', streams: number | null, date: string }> }> } | null };

export type LabelFieldsFragment = { __typename?: 'Label', id: string, name: string };

export type LabelsQueryVariables = Exact<{ [key: string]: never; }>;


export type LabelsQuery = { __typename?: 'Query', labels: { __typename?: 'LabelConnection', edges: Array<{ __typename?: 'LabelEdge', node: { __typename?: 'Label', id: string, name: string } }> } };

export type MarketFieldsFragment = { __typename?: 'Region', id: string, countryCode: string, countryName: string, translations: Array<{ __typename?: 'RegionTranslation', locale: string, countryName: string }> };

export type RegionsQueryVariables = Exact<{ [key: string]: never; }>;


export type RegionsQuery = { __typename?: 'Query', regions: Array<{ __typename?: 'Region', id: string, countryCode: string, countryName: string, translations: Array<{ __typename?: 'RegionTranslation', locale: string, countryName: string }> }> };

export const LabelAnalysisTrackFragmentDoc = gql`
    fragment LabelAnalysisTrack on Track {
  isrc
  name
  product {
    id
  }
  artists {
    id
    fullName
  }
}
    `;
export const LabelAnalysisTrackFieldsFragmentDoc = gql`
    fragment LabelAnalysisTrackFields on TrackAnalysis {
  track {
    ...LabelAnalysisTrack
  }
  weeks {
    changePercentage
    average
    interval {
      start
      end
    }
  }
  metrics {
    streams
    date
  }
  categories
}
    ${LabelAnalysisTrackFragmentDoc}`;
export const LabelFieldsFragmentDoc = gql`
    fragment LabelFields on Label {
  id
  name
}
    `;
export const MarketFieldsFragmentDoc = gql`
    fragment MarketFields on Region {
  id
  countryCode
  countryName
  translations {
    locale
    countryName
  }
}
    `;
export const LabelTrackAnalysisDocument = gql`
    query LabelTrackAnalysis($labelId: ID!, $trackAnalysisOptions: TrackAnalysisOptions) {
  label(id: $labelId) {
    id
    trackAnalysis(options: $trackAnalysisOptions) {
      ...LabelAnalysisTrackFields
    }
  }
}
    ${LabelAnalysisTrackFieldsFragmentDoc}`;

export function useLabelTrackAnalysisQuery(options: Omit<Urql.UseQueryArgs<LabelTrackAnalysisQueryVariables>, 'query'>) {
  return Urql.useQuery<LabelTrackAnalysisQuery>({ query: LabelTrackAnalysisDocument, ...options });
};
export const LabelsDocument = gql`
    query Labels {
  labels {
    edges {
      node {
        ...LabelFields
      }
    }
  }
}
    ${LabelFieldsFragmentDoc}`;

export function useLabelsQuery(options?: Omit<Urql.UseQueryArgs<LabelsQueryVariables>, 'query'>) {
  return Urql.useQuery<LabelsQuery>({ query: LabelsDocument, ...options });
};
export const RegionsDocument = gql`
    query Regions {
  regions {
    ...MarketFields
  }
}
    ${MarketFieldsFragmentDoc}`;

export function useRegionsQuery(options?: Omit<Urql.UseQueryArgs<RegionsQueryVariables>, 'query'>) {
  return Urql.useQuery<RegionsQuery>({ query: RegionsDocument, ...options });
};