import { useEffect } from "react";
import amplitude from "amplitude-js";
import { User } from "@auth0/auth0-react";
import { config } from "../config";

export function useInitAmplitude(user?: User) {
  useEffect(() => {
    const a = amplitude.getInstance();
    if (config?.amplitude?.apiKey) {
      a.init(config.amplitude.apiKey);

      if (user?.sub) {
        a.setUserId(user.sub);
      }
      if (user?.email) {
        a.setUserProperties({
          email: user.email,
        });
      }
    }
  }, [user]);
}
